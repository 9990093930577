import React from 'react';
import Layout from '../components/layout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import {
  CenteredContentBlock,
  Button,
  Grid,
  Column,
} from '../components/styleBook';

const RentalPage = () => {
  return (
    <Layout>
      <section className="rental">
        <h1 id="vuokraus">Vuokraus</h1>

        <CenteredContentBlock>
          Timeimage Oy tarjoaa edullisesti laajan valikoiman vuokrattavia
          laitteita erilaisiin käyttötarkoituksiin. Videotykkejä olemme
          vuokranneet useisiin tilaisuuksiin ja tapahtumiin. Tarkka valikoima
          vuokrattavista tuotteistamme löytyy verkkokauppamme{' '}
          <OutboundLink href="https://www.timeimage.fi/verkkokauppa/vuokraus">
            Vuokraus-sivulta
          </OutboundLink>
          .
        </CenteredContentBlock>

        <Grid>
          <Column>
            <p className="highlight">
              Ota yhteyttä numeroon <em>0500 434 040</em> ja etsimme sinulle
              sopivan laitekokoonpanon sekä sovimme laitteiden toimituksesta tai
              noudosta.
            </p>

            <p>Vaihtoehtoisesti voit ottaa yhteyttä palautelomakkeen kautta.</p>

            <Button
              href="https://www.timeimage.fi/verkkokauppa/palaute"
              as={OutboundLink}
            >
              Ota yhteyttä
            </Button>
          </Column>

          <Column>
            <h2>Videotykit</h2>
            <div>
              <img
                src="https://www.timeimage.fi/cat/images/h6510bd-product-sku-main_1308_f2f.png"
                alt="videotykin vuokraus"
              />
            </div>
            <span>
              Erikoistarjous:{' '}
              <OutboundLink href="https://www.timeimage.fi/cat/product_details.php?p=215730">
                1080p videotykki
              </OutboundLink>{' '}
              41.40&euro; / vrk
            </span>
            <h2>Valkokankaat</h2>
            <p>
              Kattoon asennettavat tai omilla jaloillaan seisovat mallit
              kaikenlaiseen käyttöön. Alkaen 19.90&euro; / vrk.
            </p>
          </Column>
        </Grid>
      </section>
    </Layout>
  );
};

export default RentalPage;
